<template>
  <div
    style="position: relative; height: calc(100vh - 40px); overflow-y: scroll"
  >
    <el-backtop target=".el-main"></el-backtop>

    <Breadcrumb :Breadcrumb="BreadcrumbCon"></Breadcrumb>

    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="{ background: '#e5e5e5', color: '#4b4b4b' }"
      >
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="iconUrl" label="商品图片">
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.iconUrl"
              :preview-src-list="[scope.row.iconUrl]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="goodsName"
          label="商品名称"
          align="center"
          width="160"
        >
        </el-table-column>
        <el-table-column prop="skuPrice" label="商品价格"> </el-table-column>
        <el-table-column prop="goodsNum" label="商品数量"> </el-table-column>
        <el-table-column
          prop="skuAttr"
          label="商品规格"
          align="center"
          width="190"
        >
        </el-table-column>
        <el-table-column prop="orderIdcopy" label="订单编号"> </el-table-column>
        <el-table-column prop="payType" label="实收款" align="center">
          <template slot-scope="scope">
            ¥{{
              scope.row.payType == 1
                ? scope.row.amount
                : scope.row.payType == 7
                ? scope.row.amountUsdt
                : scope.row.payType == 8
                ? scope.row.amountToken
                : scope.row.payType == 10
                ? scope.row.p95Amount
                : 0
            }}<br />(快递：0.00)
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="下单时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="orderStatus"
          label="交易状态"
          align="center"
          fixed="right"
          width="110"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.orderStatus }}</div>
            <el-button size="mini" type="text" @click="goDetail(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";

export default {
  components: { Breadcrumb, Pagination },
  data() {
    return {
      tableData: [],
      BreadcrumbCon: [
        {
          name: "趣宝商城",
        },
        {
          name: "趣宝盒预售",
        },
        {
          name: "待付款订单",
        },
      ], // 面包屑数据

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 50, 100, 200, 500, 1000],
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.presaleOrderVait,
      method: "POST",
      params: JSON.stringify({
        pageNum: "",
        pageSize: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          hideLoading();
          this.dataList(res.data.data.list);

          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    goDetail(row) {
      this.$router.push("/YSOrderDetail/vait");
      localStorage.setItem("orderYSDetail", JSON.stringify(row));
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.presaleOrderVait,
        method: "POST",
        params: JSON.stringify({
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            hideLoading();
            this.dataList(res.data.data.list);

            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    dataList(data) {
      let arrList = [];
      data.map((item) => {
        const { orderId } = item.mallOrderListResponse.mallOrderInfo;

        let obj = {};
        obj = {
          ...item.mallOrderListResponse.goodsInfo,
          ...item.mallOrderListResponse.mallOrderInfo,
          ...item.mallOrderListResponse.payOrderInfo,
          ...item.mallOrderListResponse.skuInfo,
        };
        obj.orderIdcopy = orderId;

        arrList.push(obj);
      });
      this.tableData = arrList;

      arrList.map((item) => {
        if (item.isPaid == false) {
          item.orderStatus = "等待买家付款";
        } else if (item.isPaid == true && item.isShip == false) {
          item.orderStatus = "待发货";
        } else if (
          item.isPaid == true &&
          item.isShip == true &&
          item.isConfirm == false
        ) {
          item.orderStatus = "已发货";
        } else if (
          item.isPaid == true &&
          item.isShip == true &&
          item.isConfirm == true
        ) {
          item.orderStatus = "已签收";
        } else if (item.isCancel == true) {
          item.orderStatus = "已取消";
        }

        item.createTime = new Date(item.createTime).toLocaleString();
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
